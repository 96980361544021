body {
  margin: 0;
  font-family: "Arial", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(138, 206, 183);
  background: linear-gradient( 90deg, rgba(138, 206, 183, 1) 0%, rgba(20, 157, 153, 1) 25%, rgba(20, 157, 153, 1) 75%, rgba(138, 206, 183, 1) 100%);
}

main {
  min-height: calc(100vh - 150px);
}

.hide {
  display: none;
}

.jts-container {
  display: flex;
  flex-direction: column;
}

.scanner-container {
  display: flex;
  flex-direction: column;
}

.scanner-loader {
  margin: 0 auto;
  margin-top: 50%;
}

.scanner {
  text-align: center;
}

.scan-title {
  color: white;
  font-weight: 500;
  padding: 20px;
}

.btn-action {
  padding: 20px 40px;
  width: 280px;
  margin: 0 auto;
  border-radius: 10px;
  background: white;
  color: #000 !important;
  font-size: 20px;
  font-weight: 600;
  border: 1px solid grey;
  margin-top: 20px;
}

footer {
  display: flex;
  font-size: 2vh;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
}

.content {
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.content h2 {
  color: white;
  text-align: center;
  font-size: 6vw;
  font-weight: 500;
  padding-top: 40px;
  padding-bottom: 20px;
}

.content p {
  color: white;
  font-size: 3.7vw;
  padding: 0 30px;
  text-align: center;
}

.content a {
  text-decoration: none;
  color: white;
  font-weight: bold;
}

.content ol, .content ul {
  padding-left: 60px;
  padding-right: 30px;
  color: white;
  font-size: 3.5vw;
}

.img-icon {
  max-width: 30%;
  height: auto;
  margin: 0 auto;
  display: block;
}

.img-icon-w {
  max-width: 40%;
  height: auto;
  margin: 0 auto;
  display: block;
}

.foto-name {
  display: block;
  padding: 10px;
  margin: 10px auto;
}

.flex {
  display: flex;
  padding: 0 20px;
  align-items: center;
}

.flex-wrap {
  flex-wrap: wrap;
  gap: 2%;
}

.flex-column {
  display: flex;
  padding: 0 20px;
  align-items: center;
  flex-direction: column;
}

.mt-4 {
  margin-top: 40px;
}

.result-img {
  width: 50%;
  text-align: center;
}

.result-img img {
  width: 80%;
}

.result-label {
  width: 50%;
}

.result-label img {
  width: 80%;
}

.result-valid {
  font-weight: 800;
  color: #cd1416 !important;
}

.result-text {
  font-size: 4vw !important;
}

footer div {
  flex: 1 1 0;
  text-align: center;
}

footer a {
  text-decoration: none;
  color: white;
  cursor: pointer;
}